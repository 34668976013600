// Variable overrides
// font
$font-family-base: 'Roboto' !important;
//estilos sidebar
$sidebar-dark-bg: #013259;
$sidebar-dark-brand-bg: #013259;
$sidebar-dark-minimizer-bg: #013259;
$sidebar-dark-nav-link-hover-bg:rgba(1, 105, 183, 0.5);
$sidebar-dark-nav-link-hover-color:#FFBC2D;
//estilos header
$header-light-bg:#015697;
$header-light-color: white; 
$header-light-hover-color: white;


// $input-focus-border-color:#017FDD;
$input-btn-focus-color: #017FDD;
// $custom-select-focus-box-shadow:none;
$custom-select-focus-width: 0.1rem;

$custom-control-indicator-focus-box-shadow: 0 0 0 0.1rem;

$theme-colors: (
  "results-legend": #017FDD
);

