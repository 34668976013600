// Here you can add other styles
.bold {
  font-weight: bold;
}
//estilos HeadCard
.h-card {
  background: #00beb1 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.h-card-matrix {
  background: #e0e0e0 !important;
  color: #1a1a1a !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn-login {
  background: #0169b7;
  height: 45px;
  color: #ffffff;
  &:hover {
    color: #ffffff !important;
  }
}
.btn-add {
  background: #0169b7;
  border: 1px solid #0169b7 !important;
  color: #ffffff;
  &:hover {
    color: #ffffff !important;
  }
}

.btn-back,
.btn-dashboard {
  background: transparent;
  color: #0169b7;
}

.btn-back {
  border: 1px solid #0169b7 !important;
}

.btn-dashboard {
  border: 2px solid #0169b7 !important;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 7px !important;
}

.f-none {
  &:focus {
    outline: none !important;
  }
}

.c-shadow {
  &:focus {
    box-shadow: 0 0 0 0.1rem #017fdd;
  }
}
.bg-login {
  background: #013259;
}
.btn-logout {
  color: #4f5d73;
  &:hover {
    text-decoration: none;
    color: #4f5d73;
  }
}
.color-layout {
  background: #013259;
  color: #ffffff;
}
.input-group-search {
  .form-control {
    &:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}

.bg-search {
  background-color: #00beb1;

  &.input-group-text {
    padding: 0.3rem 0.75rem;
    cursor: pointer;
  }
}
.jumbotron-view {
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-color: #eeeeee;
  padding: 1rem 1rem;
}

.page-item {
  &.active {
    .page-link {
      background-color: #00beb1;
      border-color: #00beb1;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.status {
  background: #ffc64b;
  border-radius: 8px;
  width: 70%;
  padding: 10px;
  color: #ffffff;
}

.status .icon-status {
  height: 26px !important;
  width: 27px !important;
  margin-right: 10px;
}
.title-modal {
  color: #0f0f0f;
}
.title-dark {
  color: #1a1a1a;
}
.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  width: 100%;
  thead tr {
    background-color: #eeeeee;
    border-bottom: 1px solid #e0e0e0;
  }
  th,
  td {
    padding: 12px 15px;
  }
  tbody tr:nth-of-type(even) {
    background-color: #eeeeee;
  }
}

.table-responsive {
  overflow-x: unset !important;

  @media (max-width: 1228.98px) {
    overflow-x: auto !important;
  }
}
.bill-column {
  font-size: 12px;
}
.drop {
  display: inline;
  .btn-drop {
    box-shadow: none !important;
    padding: 0px;
    &:after {
      display: none !important;
    }
  }
}

.datePicker {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #5c6873;
  background-color: #fff;
  border-color: #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-popper {
  width: max-content;
}

.circle-blue,
.circle-yellow,
.circle-red,
.circle-files {
  border-radius: 50%;
  width: 118px;
  height: 118px;
  padding: 25px;
  font-size: 40px;
  color: #ffff;
  text-align: center;
}
.circle-blue {
  background-color: #00c2ed;
}
.circle-yellow {
  background-color: #ffd542;
}
.circle-red {
  background-color: #ff5454;
}
.circle-files {
  background-color: #00d1c3;
}
.card-dashboard {
  box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06) !important;
  min-height: 330px !important;
}

.warning-file {
  height: 48px;
  line-height: 48px;
  background: #ffd542;
  border-radius: 10px;
  color: #ffffff;
}
#toRecive {
  &:hover {
    background-color: #00c2ed;
    .card-body {
      h5 {
        color: #ffffff;
      }
      .circle-blue {
        background-color: #33cef1;
      }
      .btn-dashboard {
        background-color: #ffffff;
        border: 2px solid #ffffff !important;
        &:hover {
          color: #0169b7;
        }
      }
      #info {
        p {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
#inProcess {
  &:hover {
    background-color: #ffd542;
    .card-body {
      h5 {
        color: #ffffff;
      }
      .circle-yellow {
        background-color: #ffdd68;
      }
      #info {
        p {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

#overdue {
  &:hover {
    background-color: #ff5454;
    .card-body {
      h5 {
        color: #ffffff;
      }
      .circle-red {
        background-color: #ff7676;
      }
      #info {
        p {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

#files {
  &:hover {
    background-color: #00d1c3;
    .card-body {
      h5 {
        color: #ffffff;
      }
      .circle-files {
        background-color: #33dacf;
      }
      #info {
        p {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}


.card-statics {
  box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06) !important;
  min-height: 355px !important;
  .title-info-totals {
    h5 {
      @media screen and (min-width: 1200px) and (max-width: 1424px) {
        min-height: 41px;
      }
    }
  }
  .circle-statistics-blue,
  .circle-statistics-yellow,
  .circle-statistics-red,
  .circle-statistics-files {
    border-radius: 50%;
    width: 136px;
    height: 133px;
    font-size: 40px;
    color: #ffff;
    text-align: center;
    padding-top: 35px;
    @media screen and (min-width: 1200px) and (max-width: 1424px) {
      width: 90px;
      height: 95px;
      font-size: 29px;
      color: #ffff;
      padding-top: 24px;
    }
  }
  .circle-statistics-blue {
    background-color: #00c2ed;
  }
  .circle-statistics-yellow {
    background-color: #ffd542;
  }
  .circle-statistics-red {
    background-color: #ff5454;
  }
  .circle-statistics-files {
    background-color: #00d1c3;
  }
}

.item-aleatory {
  padding: 0.375rem 0.75rem;
  background-color: #ced2d8;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.c-not-found{
  min-height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.colmena {
  // justify-content: end;
  // text-align: end;
  margin-top: -175px;

  @media (max-width: 575.98px) {
    margin-left: 40px;
  }
  @media (min-width: 575.98px) {
    margin-left: 189px;
  }

  @media (min-width: 767.98px) {
    margin-left: 280px;
  }

  @media (min-width: 991.98px) {
    // margin-right: 42px;
    margin-left: 439px;
  }

  @media (min-width: 1199.98px) {
    margin-left: 631px;
  }
}
